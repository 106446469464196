import $ from 'jquery';

const $menu  = $('#c-people__filter-menu');
const $peeps = $('.c-people__list-item');

export default function init() {

    $menu.on('change', function(){
        
        let department = $(this).val();
        console.log(department);

        if (department == "") {
            $peeps
                .removeClass("is-animated")
                .fadeOut()
                .promise()
                .done(function () {
                    $peeps.addClass("is-animated").fadeIn();
                });
        } else {
            $peeps
                .removeClass("is-animated")
                .fadeOut()
                .promise()
                .done(function () {
                    $peeps
                        .filter(`[data-departments*="${department}"]`)
                        .addClass("is-animated")
                        .fadeIn();
                });
        }

    });


    
}
import $ from 'jquery';

export default function init() {

    // Add fixed header state
    function setHeader() {

        if ( $(window).scrollTop() > 20) {
            $('body').addClass('scrolled');
        } else {
            $('body').removeClass('scrolled');
        }
    }
    setHeader();
    
    $(window).on('scroll', function() {
        debouncer( setHeader, 100 );
    });

    // Add other functions here
    var timerId;
    var debouncer = function (func, delay) {

        // cancel setTimeout method
        clearTimeout(timerId);
    
        // call function after timeout
        timerId  =  setTimeout(func, delay)
    }

    
}
import $ from 'jquery';

export default function init() {

    const setClickBoxes = function() {

        $('.c-letters__list-item').on('click', function(e) {

            if ( $(this).hasClass('c-letters__list-item-achive')) {
                e.preventDefault();
                $('a.c-modal__btn-continue').attr('href', $(this).find('a').attr('href') );
                MicroModal.show('letter-link-modal');
            } else {
                $(this).find('a').first().trigger('click');
            }

        });
    
    }

    setClickBoxes();


    $('#letters-filter').on('change', function() {
        
        let year = $(this).val();

        $.ajax({
            type: 'POST',
            url: '/wp/wp-admin/admin-ajax.php',
            dataType: 'html',
            data: {
                action: 'filter_letters',
                category:  year,
            },
            success: function(res) {
                $('.c-letters__list').fadeOut(
                    function(){
                        $(this).html(res).fadeIn(500);
                        setClickBoxes();
                });

            }
        });

    });

    $('#fund-letters-filter').on('change', function() {
        
        let year = $(this).val();

        $.ajax({
            type: 'POST',
            url: '/wp/wp-admin/admin-ajax.php',
            dataType: 'html',
            data: {
                action: 'filter_fund_letters',
                category:  year,
            },
            success: function(res) {
                $('.c-letters__list').fadeOut(
                    function(){
                        $(this).html(res).fadeIn(500);
                        setClickBoxes();
                });

            }
        });

    });
        
}
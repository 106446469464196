const ACCORDION_COMPONENT = '.c-what-we-do';
const ITEM_SELECTOR = '.c-what-we-do__strategies > li';
const OPEN_BUTTON = '.c-what-we-do__strategy-title-container';
const OPEN_CLASS = 'open';

export default function init() {
    const accordionComponent = Array.prototype.slice.call(document.body.querySelectorAll(ACCORDION_COMPONENT));

    if (accordionComponent) {
        accordionComponent.forEach(component => {
            const items = Array.prototype.slice.call(component.querySelectorAll(ITEM_SELECTOR));

            if (items) {
                items.forEach(item => {
                    let isOpen = false;

                    item.addEventListener('click', (e) => {
                        const openButton = e.target.closest(OPEN_BUTTON);

                        if (openButton) {
                            if (!isOpen) {
                                item.classList.add(OPEN_CLASS);
                                isOpen = true;
                            } else {
                                item.classList.remove(OPEN_CLASS);
                                isOpen = false;
                            }
                        }
                    });
                });
            }
        });
    }
}

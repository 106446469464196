import $ from 'jquery';
import { typeahead } from 'corejs-typeahead';

export default function init() {

    if (typeof people_names !== 'undefined') {

        let substringMatcher = function (strs) {
            return function findMatches(q, cb) {
                let matches, substringRegex;

                // an array that will be populated with substring matches
                matches = [];

                // regex used to determine if a string contains the substring `q`
                let substrRegex = new RegExp(q, "i");

                // iterate through the pool of strings and for any string that
                // contains the substring `q`, add it to the `matches` array
                $.each(strs, function (i, str) {
                    if (substrRegex.test(str)) {
                        matches.push(str);
                    }
                });

                cb(matches);
            };
        };
        
        $("#c-people__search-field.typeahead").typeahead(
            {
                hint: true,
                highlight: true,
                minLength: 1,
            },
            {
                name: "people",
                source: substringMatcher(people_names),
            }
        );

    }
    
}
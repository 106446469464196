import $ from 'jquery';

const $external_links = $('a.external');
const $btn_continue = $('a.c-modal__btn-continue');
const $modal_content_divs = $('.c-modal__content-disclosures');
const $content_general = $('#disclosure-general');
const $content_portal = $('#disclosure-portal');
const $btn_cancel = $('a.c-modal__btn-cancel');
const $letter_links = $('a.letter-disclosure-link');
const $pi_disclosure = $('#pensions-investments-disclosure');

export default function init() {

    $external_links.on('click', function(e) {
        $btn_continue.attr('href', this.href );
        MicroModal.show('site-leaving-modal');
        if ( $(this).data('content') == 'portal') {
            $content_portal.show();
        } else {
            $content_general.show();
        }
        e.preventDefault();
    });

    $pi_disclosure.on('click', function(e) {
        e.preventDefault();
        MicroModal.show('disclosure-modal');
    });

    $letter_links.on('click', function(e) {
        e.stopImmediatePropagation();
        e.preventDefault();
        $btn_continue.attr('href', this.href );
        MicroModal.show('letter-link-modal');
    });

    $btn_continue.on('click', function(e) {
        MicroModal.close('site-leaving-modal');
        MicroModal.close('letter-link-modal');
        $modal_content_divs.hide();
    });

    $btn_cancel.on('click', function(e) {
        $modal_content_divs.hide();
        e.preventDefault();
    });

}
import $ from 'jquery';

export default function init() {

    $('.c-search-form__search-input').on('blur input focus', function() {
        var $field = $('label[for="' + $(this).attr('id') + '"]');
        console.log( $field );
        if ( this.value ) {
            $field.addClass('label-focused');
        } else {
            $field.removeClass('label-focused');
        }

    });

    $('.c-search-form__search-input').on('focus', function() {
        var $field = $('label[for="' + $(this).attr('id') + '"]');
        if ( this.value ) {
            $field.addClass('label-focused');
        } else {
            $field.removeClass('label-focused');
        }
    });

}
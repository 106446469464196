import $ from 'jquery';

const ACTIVE_CLASS = 'is-active';
const HAMBURGER_CLASS = 'js-hamburger';
const HEADER_CLASS = 'js-primary-header';
const OPEN_CLASS = 'is-open';
const MOBILE_NAV_CLASS = 'c-primary-header__primary-mobile-nav-items';
const NAV_ITEM_CLASS = 'c-primary-header__nav-item';
const NAV_LINK_WRAPPER_CLASS = 'c-primary-header__nav-item-link-wrapper';
const HAS_CHILDREN_CLASS = 'has-children';
const MOBILE_LINK_WRAPPER_CLASS = 'primary-mobile-nav-items-wrapper';

export default function init() {
    const $hamburger = $(`.${HAMBURGER_CLASS}`);
    const $header = $(`.${HEADER_CLASS}`);
    const $mobileItems = $(`.${MOBILE_NAV_CLASS} > .${NAV_ITEM_CLASS}.${HAS_CHILDREN_CLASS}`);

    let isMenuOpen = false;

    if ($hamburger.length > 0 && $header.length > 0) {
        $hamburger.on(
            'click',
            onMenuToggleClick
        );
    }

    if ($mobileItems.length > 0) {
        $mobileItems.on(
            'click',
            `.${NAV_LINK_WRAPPER_CLASS}`,
            onMenuItemClick
        );
    }

    function onMenuToggleClick() {
        isMenuOpen = !isMenuOpen;
        toggleMenuDisplay();
    }

    function onMenuItemClick(e) {
        if ($(e.currentTarget).hasClass(MOBILE_LINK_WRAPPER_CLASS)) {
            $(e.delegateTarget).toggleClass(OPEN_CLASS);
        }
    }

    function toggleMenuDisplay() {
        $hamburger.toggleClass(ACTIVE_CLASS, isMenuOpen);
        $header.toggleClass(OPEN_CLASS, isMenuOpen);
    }
}

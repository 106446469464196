import $ from 'jquery';

const UCITS_FORM = 'ucits-form';

export default function init() {
    const form = document.getElementById( UCITS_FORM );

    if (form) {
        form.addEventListener('submit', (event) => {

            if ( document.getElementById('agree-box').checked ) {

                var expires = new Date();
                expires.setTime(expires.getTime() + (5 * 1000));
                document.cookie = 'UCITS_AGREEMENT=true;path=/;expires=' + expires.toUTCString();
                
            } else {
                alert('Please agree to terms and conditions before proceeding.');
                event.preventDefault();
            }

        });
    }

}




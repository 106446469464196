import $ from 'jquery';

export default function init() {

    $('#search-trigger').on('click', function(e) {
        $('.c-search').fadeIn(300);
        $('#search-input-modal').trigger('focus');
        e.preventDefault();
    });

    $('.c-search__close-button').on('click', function(e) {
        $('.c-search').fadeOut(300);
        e.preventDefault();
    });

}

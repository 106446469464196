import $ from 'jquery';

const $trigger = $('#ucits-loadmore');
const $ucits_list = $('.c-ucits-data__wrapper');

export default function init() {

    $ucits_list.find('.c-ucits-data__data-row').slice(0, 5).removeClass('hide');
    if ( $ucits_list.find('.c-ucits-data__data-row').length > 5 ) {
        $trigger.removeClass('hide');
    } else {
        $trigger.addClass('hide');
    }

    $trigger.on('click', function(e) {

        e.preventDefault();
    
        $ucits_list.find('.c-ucits-data__data-row.hide').slice(0, 5).removeClass('hide');
        if ( $ucits_list.find('.c-ucits-data__data-row.hide').length == 0 ) {
            $trigger.addClass('hide');
        }
    
    });

}
import $ from 'jquery';

const $select = $('#select-regions');
const $loader = $('#kiids-loadmore');
const $list   = $('.list-kiids');

const filterKIIDs = function() {

    if ( $list.length ) {

        let results = '';
        let selected = $select.val();
        if ( selected != '' && kiid_docs ) {
            let count = 0;
            kiid_docs.forEach(function(el, index) {
                if ( 'region-' + el.region.name == selected ) {
                    count++;
                    results += `
                        <div class="c-docs__list-row hide" data-count="` + count + `">
                            <div class="c-docs__list-name">`+ el.display_title +`</div>
                            <div class="c-docs__list-file"><a target="_blank" href="`+ el.file.url +`" title="`+  el.title +` (PDF)">View</a></div>
                        </div>`;
                }
            });

            $list.fadeOut('fast', function(){
                $(this).html( results );
                $list.find('.c-docs__list-row').slice(0, 5).removeClass('hide');
                if ( $list.find('.c-docs__list-row').length > 5 ) {
                    $loader.removeClass('hide');
                } else {
                    $loader.addClass('hide');
                }
            }).fadeIn('fast');

        }

    }

}

$loader.on('click', function(e) {

    e.preventDefault();

    $list.find('.c-docs__list-row.hide').slice(0, 5).removeClass('hide');
    if ( $list.find('.c-docs__list-row.hide').length == 0 ) {
        $loader.addClass('hide');
    }

});

export default function init() {

    $select.on('change', function() {
        filterKIIDs();
    });

    filterKIIDs();


}

import $ from 'jquery';
const $strategy_menu = $('.c-strategy-nav select');


export default function init() {

    $strategy_menu.on('change', function() {

        if ( window.location.href != $(this).val() ) {
            window.location.href = $(this).val()
        }
    });

    $(document).ready(function () {

        let $pageID = $strategy_menu.data('postid');

        function set_options() {
            $strategy_menu.find('option').each(function(el) {
                if ( $pageID == $(this).data('optid') ) {
                    $(this).attr('selected', 'selected' );
                }

                let txt = $(this).text();
                if ( $(window).width() < 768) {
                    txt = txt.replace('Vulcan Value Partners ', 'VVP ');
                } else {
                    txt = txt.replace('VVP ', 'Vulcan Value Partners ');
                }
                $(this).text( txt );
            });
        }

        set_options();
        $(window).resize(set_options);

    });
}





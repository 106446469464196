import $ from 'jquery';
import accordion from './components/accordion';
import initHeader from './components/header';
import initStrategyFilter from './components/strategy-filter';
import initKIIDsFilter from './components/kiids-filter';
import initUCITSFormSubmit from './components/ucits-form';
import objectFitImages from 'object-fit-images';
import initMicroModal from './components/modal';
import initSlickSlider from './components/slider';
//import initPostBlock from './components/post-block';
import initFixedNav from './components/fixed-nav';
import initSearchModal from './components/search-modal';
import initSearchLabel from './components/search-label';
import initRowLoader from './components/row-loader';
import initLetterFilter from './components/letter-filter';
import initNewsFilter from './components/news-filter';
import initPeopleFilter from './components/people-filter';
import initPeopleSearch from './components/people-search';
import initExternalLinkPopup from './components/external-link';

objectFitImages();
accordion();
initHeader();
initStrategyFilter();
initKIIDsFilter();
initUCITSFormSubmit();
initMicroModal();
initSlickSlider();
//initPostBlock();
initFixedNav();
initSearchModal();
initSearchLabel();
initRowLoader();
initLetterFilter();
initNewsFilter();
initPeopleFilter();
initPeopleSearch();
initExternalLinkPopup();
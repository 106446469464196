import $ from 'jquery';

export default function init() {

    $('#news-filter').on('change', function() {
        
        let year = $(this).val();

        $.ajax({
            type: 'POST',
            url: '/wp/wp-admin/admin-ajax.php',
            dataType: 'html',
            data: {
                action: 'filter_news',
                year: year,
            },
            success: function(res) {
                $('.c-related-posts__wrapper').fadeOut(
                    function(){
                        $(this).html(res).fadeIn(500);
                });

            }
        });

    });
        
}